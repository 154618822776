import {
    AppLayout,
    Box,
    Button,
    Checkbox,
    ColumnLayout,
    Container,
    Form,
    FormField,
    Grid,
    Header,
    Input,
    SpaceBetween,
    TokenGroup
} from "@amzn/awsui-components-react";
import React from "react";
import { cvePattern } from "../utils/Constants";
import StatusBar from "../common/statusBar";
import { Navigation } from "../common/navBar";
import { gridDefinition } from "../common/layout";
import { submitCpes } from "../features/cpe/submitCpe";

export function CpeForm() {
    const Content = () => {
        const defaultCVE = "CVE-"
        const [cve, setCve] = React.useState(defaultCVE);
        const [library, setLibrary] = React.useState("");
        const [affectedVersionStart, setAffectedVersionStart] = React.useState('');
        const [includeAffectedVersionStart, setIncludeAffectedVersionStart] = React.useState(false);
        const [affectedVersionEnd, setAffectedVersionEnd] = React.useState('');
        const [includeAffectedVersionEnd, setIncludeAffectedVersionEnd] = React.useState(false);
        const [addedRanges, setAddedRanges] = React.useState([]);
        const [submitBtnLoading, setSubmitBtnLoading] = React.useState(false);
        const [statusMsgObj, setStatusMsgObj] = React.useState(null);


        const checkRangeValid = () => {
            if (affectedVersionStart > affectedVersionEnd || (affectedVersionStart === affectedVersionEnd && (!includeAffectedVersionStart || !includeAffectedVersionEnd))) {
                return false;
            }
            return true;
        }
        const onAdd = () => {
            if (!checkRangeValid) {
                setStatusMsgObj({ 'type': 'error', message: 'Please enter a valid range' });
                return false;
            }
            const newRange2Add = `${includeAffectedVersionStart ? '[' : '('}${affectedVersionStart} , ${affectedVersionEnd}${includeAffectedVersionEnd ? ']' : ')'}`
            let newAddedRanges = [...addedRanges];
            if (!newAddedRanges.some(item => item.label === newRange2Add)) {
                newAddedRanges.push({ "label": newRange2Add });
                setAddedRanges(newAddedRanges);
            }
            return true;
        }

        const onSubmit = async () => {
            setSubmitBtnLoading(true);
            try {
                if (!cvePattern.test(cve)) {
                    setStatusMsgObj({ 'type': 'error', message: `'${cve}' is NOT valid. Please enter a valid CVE` });
                    return;
                }
                if (library.length === 0) {
                    setStatusMsgObj({ 'type': 'error', message: 'Please enter a valid library name' });
                    return;
                }
                if (addedRanges.length === 0) {
                    setStatusMsgObj({ 'type': 'error', message: 'Please add at lease one affected range' });
                    return;
                }
                await submitCpes({ cve: cve, library: library, affectedRanges: addedRanges.map(item => item.label.replace(/\s+/g, '')) });
            } catch (e) {
                console.log(e)
                setStatusMsgObj({ 'type': 'error', message: `CPE submission failed: ${e}` })
                return;
            } finally {
                setSubmitBtnLoading(false);
            }
            setCve(defaultCVE);
            setLibrary('');
            setAddedRanges([]);
            setAffectedVersionStart('');
            setAffectedVersionEnd('');
            setIncludeAffectedVersionStart(false);
            setIncludeAffectedVersionEnd(false);

            setStatusMsgObj({
                'type': 'success', message: "Submission succeed"
            })
        }

        return (
            <Box padding={{ horizontal: 's' }}>
                <Grid
                    gridDefinition={[
                        gridDefinition
                    ]}
                >
                    <SpaceBetween size="l">
                        <Form
                            header={<Header variant="h1">Submit CPEs</Header>}
                            actions={
                                <SpaceBetween direction={"horizontal"} size={"xxl"}>
                                    <SpaceBetween direction={"horizontal"} size={"xxxs"}>
                                    </SpaceBetween>
                                    <Button variant="primary" loading={submitBtnLoading} onClick={() => onSubmit()}>Submit</Button>
                                </SpaceBetween>
                            }
                        >
                            <StatusBar statusMsgObj={statusMsgObj} />
                            <Container>
                                <ColumnLayout columns={2}>
                                    <FormField label="CVE">
                                        <Input
                                            onChange={({ detail }) => {
                                                setCve(detail.value.replaceAll(/\s/g, '').toUpperCase())
                                            }}
                                            value={cve}
                                            placeholder="CVE-2020-10757"
                                        />
                                    </FormField>
                                    <FormField label="Library">
                                        <Input
                                            onChange={({ detail }) => {
                                                setLibrary(detail.value.replaceAll(/\s/g, '').toLowerCase())
                                            }}
                                            value={library}
                                            placeholder="linux_kernel"
                                        />
                                    </FormField>
                                    <FormField label="Affected Version Starts With">
                                        <Grid
                                            gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
                                        >
                                            <Input
                                                onChange={({ detail }) => {
                                                    setAffectedVersionStart(detail.value.replaceAll(/\s/g, '').toLowerCase())
                                                }}
                                                value={affectedVersionStart}
                                                placeholder="5.1.4"
                                            />
                                        </Grid>
                                        <Checkbox onChange={({ detail }) => setIncludeAffectedVersionStart(detail.checked)} checked={includeAffectedVersionStart}>
                                            Include This Version
                                        </Checkbox>
                                    </FormField>
                                    <FormField label="Affected Version Ends With">
                                        <Grid
                                            gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
                                        >
                                            <Input
                                                onChange={({ detail }) => {
                                                    setAffectedVersionEnd(detail.value.replaceAll(/\s/g, '').toLowerCase())
                                                }}

                                                value={affectedVersionEnd}
                                                placeholder="5.4.3"
                                            />
                                        </Grid>
                                        <Checkbox onChange={({ detail }) => setIncludeAffectedVersionEnd(detail.checked)} checked={includeAffectedVersionEnd}>
                                            Include This Version
                                        </Checkbox>
                                    </FormField>
                                </ColumnLayout>
                                <br />
                                <Box textAlign="center"><Button onClick={({ detail }) => onAdd()}>Add this affected range</Button></Box>
                                <TokenGroup
                                    onDismiss={({ detail: { itemIndex } }) => {
                                        setAddedRanges([
                                            ...addedRanges.slice(0, itemIndex),
                                            ...addedRanges.slice(itemIndex + 1)
                                        ]);
                                    }}
                                    items={addedRanges}
                                />
                            </Container>
                        </Form>
                    </SpaceBetween>
                </Grid>
            </Box>
        )
    }

    const [navigationOpen, setNavigationOpen] = React.useState(true);

    return (
        <AppLayout
            stickyNotifications
            disableContentPaddings={false}
            content={<Content />}
            navigation={<Navigation activeHref="/" />}
            navigationOpen={navigationOpen}
            navigationWidth={200}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            toolsHide={true}
        />
    );
}