import { CognitoAuth } from "amazon-cognito-auth-js";
import { getCurEnv } from "../../utils/envVarsReader";
import { getAuthConfig } from "../../utils/Constants";


let JWTToken;

// these are local storage keys since Cognito writes the token into local storage
const lastUrlKey = "lastUrl";
const expireTimeKey = "expireTime";
const interval = 10 * 1000;     // 10 sec


//User authentication and authorization
export const auth = async () => {
    const userAlias = await setTokenInfo();    // durantion is in sec
    if (userAlias) {
        setInterval(checkTimeAndGetNewToken, interval)    // time in milliseconds
    }
    return userAlias;
}


const checkTimeAndGetNewToken = () => {
    const expireTimestampInSec = localStorage.getItem(expireTimeKey);
    const timeNowInSec = Date.now() / 1000;  // ms to sec
    if (timeNowInSec >= expireTimestampInSec) {
        //console.log("token expired, auto renew");
        setTokenInfo(null, true);
    }
}


// set jwtToken and expireTime from auth object; get user alias
const setTokenInfo = async () => {
    let decodedAuthObj;
    try {
        let authObj;
        if (getCurEnv() === 'local') {
            decodedAuthObj = getDummyAuthObj();
            JWTToken = "dummyJWT"
        } else {
            authObj = await generateOrGetAuthObj();
            decodedAuthObj = authObj.decodePayload();
            JWTToken = authObj.getJwtToken();

            localStorage.setItem(expireTimeKey, decodedAuthObj.exp)    // set expire time for the token
            console.log(authObj);
        }
        //logger.info(`get auth object: ${JSON.stringify(decodedAuthObj, null, 4)}`);
    } catch (e) {
        // only do the unauth flow if this is the first time trying to auth
        console.warn(`get auth object failed: ${e}`);
        throw e;
    }

    const identities = decodedAuthObj.identities;
    if (!identities && identities.length !== 1) {
        const msg = `The identities field in JWT token ${identities ? 'is null' : `has ${identities.length} elements: ${JSON.stringify(identities, null, 4)}`}`;
        console.error(`The identities field in JWT token ${identities ? 'is null' : `has ${identities.length} elements: ${JSON.stringify(identities, null, 4)}`}`);
        throw Error(msg);
    }

    return identities[0].userId;
}

export const getJwtToken = () => {
    return JWTToken;
}

function redirectToOriginalPage() {
    // Replace the href because the Cognito passes the OAuth2 grant code in the query string and the grant code is not reusable
    if (window.history.length > 0) {
        window.history.replaceState(undefined, 'CPE', localStorage.getItem(lastUrlKey));
    }
}

const generateOrGetAuthObj = () => {
    return new Promise((resolve, reject) => {
        const authConfig = getAuthConfig();
        const auth = new CognitoAuth(authConfig)

        auth.userhandler = {
            onFailure: (err) => {
                redirectToOriginalPage();
                reject(err);
            },
            onSuccess: (result) => {
                redirectToOriginalPage();
                resolve(auth.getSignInUserSession().getIdToken());
            }
        };

        auth.useCodeGrantFlow();

        const href = window.location.href;
        const session = auth.getSignInUserSession();

        if (session.isValid()) {
            resolve(session.getIdToken());
        } else if (href.indexOf('?code') > 0) {
            auth.parseCognitoWebResponse(href);     // triggers userhandler
        } else {
            auth.clearCachedTokensScopes();
            auth.getSession();
            localStorage.setItem(lastUrlKey, href);
        }
    });
}

const getDummyAuthObj = () => {
    return {
        identities: [
            {
                userId: "test"
            }
        ],
        iat: 0,
        exp: 10,        // in sec 
    }
}