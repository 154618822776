import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { CpeForm } from "./pages/form";
import { ContentLayout, Flashbar } from "@amzn/awsui-components-react";
import { MyHeader } from "./common/myHeader";
import "./styles/header.css"
import React from "react";
import { preLoad } from "./preLoad";
import { useDispatch, useSelector } from "react-redux";
import { selectUserAlias } from "./features/auth/userAliasSlice";



function App() {
    const [error, setError] = React.useState(null);
    const dispatch = useDispatch();
    const userAlias = useSelector(selectUserAlias);
    React.useEffect(() => {
        preLoad(dispatch).catch(e => setError(e));
    }, [dispatch])

    if (error) {
        console.error(error);
    }

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route>
                < Route exact path="/" element={<CpeForm />} />
            </Route>

        )
    )
    return (
        userAlias ?
        <ContentLayout class="no-padding" header={<MyHeader />}>
            <RouterProvider router={router} />
        </ContentLayout> 
        :
        <Flashbar
            items={[
                {
                    type: "success",
                    loading: true,
                    content: "Authenticating and Authorizing"
                }
            ]}
        />
        )
}

export default App;