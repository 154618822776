import { Box, Header, Link, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { useSelector } from "react-redux";
import { selectUserAlias } from "../features/auth/userAliasSlice";

function UserProfile(props) {
    if (props.user) {
        return (
            <Box>Welcome, <Link href={`https://phonetool.amazon.com/users/${props.user}`} target='_blank'><b>{props.user}</b></Link></Box>
        );
    }

    //No user, return empty indicator
    return <b>--</b>;
}

export function MyHeader() {
    const userAlias = useSelector(selectUserAlias);

    return <Header className="header"
        variant="h1"
        actions={
            <div>
                <SpaceBetween direction="horizontal" size="s">
                    <UserProfile user={userAlias} />
                </SpaceBetween>
            </div>
        }
    >
        <div>CPE Dashboard</div>
    </Header>
}